<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard de Serviços</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Visão Aneel
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
    </v-tabs>

    <v-card class="mt-0 mb-5">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-3" justify="space-between">
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col v-if="tab === 0" cols="12" sm="3">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>
          <v-col v-if="tab !== 0" cols="12" sm="3">
            <input-month
              label="Competência"
              rules="min:7"
              :minMonth="'2023-01'"
              :selectedMonth="competencia"
              @monthSelected="setCompetencia"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-btn color="primary" class="mb-4" @click="dialogMetasKpis = true">
              Gerenciar metas dos KPIs
              <v-icon class="ml-2">
                mdi-open-in-app
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mx-auto" justify="space-between">
          <v-col cols="12" sm="5">
            <v-select
              :items="itemsSelectCategoriaServicos"
              v-model="categoriaServicosSelecionada"
              label="Categoria de Serviços"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-select
              :items="itemsSelectServico"
              v-model="servicoSelecionado"
              label="Serviço"
            />
          </v-col>
        </v-row>

        <v-row v-if="tab === 2" class="mx-auto" justify="space-between">
          <v-col cols="12" sm="1">
            <v-select
              :items="regionaisList"
              v-model="regionalSelecionada"
              label="Regional selecionada"
            />
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              :items="municipios"
              v-model="municipioSelecionado"
              clearable
              label="Município selecionado"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-select
              :items="listaKpisOrdenacao"
              v-model="kpiOrdenacao"
              label="Ordem Gráficos"
              :append-icon="iconTipoOrdenacao"
              @click:append="
                tipoOrdenacao === 'asc'
                  ? (tipoOrdenacao = 'desc')
                  : (tipoOrdenacao = 'asc')
              "
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <dashboard-servicos-geral
      v-if="tab === 0 && competenciaDe != '' && competenciaAte != ''"
      :competenciaDe="competenciaDe"
      :competenciaAte="competenciaAte"
      :activatedTab="tab"
      :servicoSelecionado="servicoSelecionado"
      :traducaoIndicadores="traducaoIndicadores"
      @updateLoadingDados="loadingDados = $event"
    />
    <dashboard-servicos-visao-aneel
      v-else-if="tab === 1 && competencia != ''"
      :competencia="competencia"
      :activatedTab="tab"
      :servicoSelecionado="servicoSelecionado"
      :categories="categories"
      :traducaoIndicadores="traducaoIndicadores"
      @updateLoadingDados="loadingDados = $event"
    />
    <dashboard-servicos-visao-municipios
      v-else-if="tab === 2 && competencia != ''"
      :competencia="competencia"
      :activatedTab="tab"
      :servicoSelecionado="servicoSelecionado"
      :municipios="municipios"
      :municipioSelecionado="municipioSelecionado"
      :tipoOrdenacao="tipoOrdenacao"
      :kpiOrdenacao="kpiOrdenacao"
      :traducaoIndicadores="traducaoIndicadores"
      :regionalSelecionada="regionalSelecionadaId"
      @updateLoadingDados="loadingDados = $event"
    />

    <dialog-gerenciamento-metas-kpis
      v-if="dialogMetasKpis"
      :dialog.sync="dialogMetasKpis"
      ref="metasKpis"
      @importaMetasKpis="importaMetasKpis()"
      @baixarMetasKpis="baixarMetasKpis($event)"
    >
      <template v-slot:cadatro-geral-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold"
          >mes; ano; servico; pendentes; pendentes_no_prazo;
          pendentes_em_atraso; psa; pat; suspensos; concluidos;
          concluidos_no_prazo; concluidos_fora_prazo; prazo_medio_total; patc;
          solicitacoes; compensacao</span
        >
      </template>
      <template v-slot:cadatro-municipios-extra-info>
        Deve conter as colunas:
        <span class="font-weight-bold"
          >codigo_municipio; mes; ano; servico; pendentes; pendentes_no_prazo;
          pendentes_fora_prazo; psa; pat; suspensos; concluidos;
          concluidos_no_prazo; concluidos_fora_prazo; psfp; patc; solicitacoes;
          compensacoes</span
        >
      </template>
    </dialog-gerenciamento-metas-kpis>

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </v-container>
</template>

<script>
  import DashboardServicosService from "@/services/DashboardServicosService";

  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service";
  import MunicipiosService from "@/services/MunicipiosService";

  import dayjs from "dayjs";

  export default {
    name: "DashboardServicos",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      DashboardServicosGeral: () =>
        import("@/components/dashboardServicos/DashboardServicosGeral.vue"),
      DashboardServicosVisaoAneel: () =>
        import(
          "@/components/dashboardServicos/DashboardServicosVisaoAneel.vue"
        ),
      DashboardServicosVisaoMunicipios: () =>
        import(
          "@/components/dashboardServicos/DashboardServicosVisaoMunicipios.vue"
        ),
      DialogGerenciamentoMetasKpis: () =>
        import(
          "@/components/paineisGerenciais/DialogGerenciamentoMetasKpis.vue"
        ),
    },
    data: () => ({
      tab: 1,
      competencia: "",
      competenciaDe: "",
      competenciaAte: "",
      loadingDados: true,
      categoriasServicos: [],
      categoriaServicosSelecionada: -1,
      servicoSelecionado: -1,
      municipios: [],
      municipioSelecionado: null,
      regionais: [],
      regionalSelecionada: null,
      dialogMetasKpis: false,
      listaKpisOrdenacao: [
        { text: "Pendentes", value: "pendentes" },
        { text: "PSA", value: "psa" },
        { text: "PSA", value: "psa" },
        { text: "PAT", value: "pat" },
        { text: "Compensações", value: "compensacoes" },
      ],
      kpiOrdenacao: "pendentes",
      tipoOrdenacao: "desc",
      traducaoIndicadores: {
        psa: "Percentual de Serviços em Atraso (Pendentes)",
        pat: "Prazo Médio de Atraso",
        patc: "Prazo de Atraso Serviços Realizados",
        psfp: "Percentual de Serviços Fora Prazo (Realizados)",
      },
    }),
    computed: {
      categories() {
        const startDate = "2023-01";
        const endDate = dayjs().format("YYYY-MM");

        return generateMonthlyDateRangeArray(startDate, endDate, "YYYY-MM");
      },
      itemsSelectCategoriaServicos() {
        return this.categoriasServicos.map((categoria) => {
          return { text: categoria.nome, value: categoria.id };
        });
      },
      itemsSelectServico() {
        const index = this.categoriasServicos.findIndex(
          (categoria) => categoria.id === this.categoriaServicosSelecionada
        );

        return this.categoriasServicos[index]?.tipos_servicos.map(
          (tipo_servico) => {
            return tipo_servico.codigo === -1
              ? { text: tipo_servico.servico, value: tipo_servico.codigo }
              : {
                  text: `Serviço ${tipo_servico.codigo} - ${tipo_servico.detalhe_servico.artigo_ren_1000} - ${tipo_servico.detalhe_servico.nome_servico}`,
                  value: tipo_servico.codigo,
                };
          }
        );
      },
      iconTipoOrdenacao() {
        if (this.tipoOrdenacao === "asc") return "mdi-sort-reverse-variant";
        else if (this.tipoOrdenacao === "desc") return "mdi-sort-variant";
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
    },
    async created() {
      let competencia = await IndicadoresServicosV2Service.getCompetenciaDadosRecentes();
      this.setCompetencia(competencia.data.max_competencia);
      this.setCompetenciaDe(competencia.data.max_competencia);
      this.setCompetenciaAte(competencia.data.max_competencia);
      this.getCategoriasServicos();
      this.getMunicipios();
    },
    methods: {
      setCompetencia(data) {
        this.competencia = data;
      },
      setCompetenciaDe(data) {
        this.competenciaDe = data;
      },
      setCompetenciaAte(data) {
        this.competenciaAte = data;
      },
      getCategoriasServicos() {
        return IndicadoresServicosV2Service.getCategoriasServicos()
          .then((res) => {
            this.categoriasServicos = res;

            this.categoriasServicos.unshift({
              id: -1,
              nome: "TUDO",
              tipos_servicos: [{ codigo: -1, servico: "Todos os Serviços" }],
            });
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar categorias de serviços.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getMunicipios() {
        return MunicipiosService.getMunicipios(this.regionalSelecionadaId)
          .then((res) => {
            this.municipios = res.map((el) => el.nome_municipio);
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar municípios.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      importaMetasKpis() {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardServicosService.importarMetasKpis(
          ref.tipoDeCadastro,
          ref.lastUploadedFile.id
        )
          .then((res) => {
            this.$toast.success("Metas salvas com sucesso", "", {
              position: "topRight",
            });
            ref.uploadedFiles = [];
            ref.dialogLocal = false;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao salvar metas", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      baixarMetasKpis(tipoDeCadastro) {
        const ref = this.$refs.metasKpis;

        ref.loading = true;

        DashboardServicosService.baixarMetasKpis(tipoDeCadastro, {
          timeout: 60 * 60 * 1000,
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Metas dos KPIs - ${tipoDeCadastro}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro ao baixar Metas dos KPIs", "", {
              position: "topRight",
            });
          })
          .finally(() => (ref.loading = false));
      },
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.regionalSelecionada = res.find(
                (el) => el.nome.toLowerCase() == "corporativa"
              ).nome;
            }
          })
          .catch((err) => {
            throw err;
          });
      },
    },
    watch: {
      categoriaServicosSelecionada() {
        const index = this.categoriasServicos.findIndex(
          (categoria) => categoria.id === this.categoriaServicosSelecionada
        );

        this.servicoSelecionado = this.categoriasServicos[
          index
        ].tipos_servicos[0].codigo;
      },
      tab(newValue, oldValue) {
        if (newValue === 2) {
          this.getRegionais();
        }
        this.getMunicipios();
      },
      regionalSelecionada() {
        this.municipioSelecionado = null;
        this.getMunicipios();
      },
    },
  };
</script>

<style></style>
